
import moment from 'moment'
import Vue, { PropType } from 'vue'
import api from '../../api/api'

import Breadcrumbs from '../../components/Breadcrumbs.vue'
import ClientIconsMenu from '../../components/iconLinks/ClientIconsMenu.vue'
import SpinnerLoader from '../../components/SpinnerLoader.vue'
import { Connector, Project } from '../../types/main'
import { CONNECTOR_TYPES } from '../../vars/general'
import BaseInputOutlined from '../../components/inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../components/inputs/CustomActivatorSelect.vue'
import _ from 'lodash'
import { numSort, floatNumSort } from '../../utils'

export default Vue.extend({
    name: 'GCB2MarketingReport',
    components: {
        Breadcrumbs,
        SpinnerLoader,
        ClientIconsMenu,
        CustomActivatorSelect,
        BaseInputOutlined,
    },

    data: () => ({
        pageLoading: false,
        dashboardDataLoading: false,
        dateMenu: false,
        dates: [] as string[],
        staffNames: [] as string[],
        staffNamesList: [] as string[],
        dateFormat: 'DD.MM.YYYY',
        datePickerDateFormat: 'YYYY-MM-DD',
        filters: { productsDisplay: 0 } as any,
        filtersLists: {} as any,
        inititalFilters: {},
        projectType: null as any,
        statByStaffDataHeaders: [
            { text: 'Мастер', value: 'staffName' },
            {
                text: 'Выручка за услуги',
                value: 'servRev',
                sort: numSort,
                class: 'h-servRev',
                desc: `Сумма выручки по услугам, с учетом всех скидок.`,
            },
            {
                text: 'Выручка за товары',
                value: 'prodRev',
                sort: numSort,
                class: 'h-prodRev',
                desc: `Сумма выручки по товарам, с учетом всех скидок.`,
            },
            {
                text: 'Визитов',
                value: 'visits',
                sort: numSort,
                class: 'h-visits',
                desc: `Количество состоявшихся визитов. Деление на визиты берется из CRM. Визиты, в которые клиент посещал больше одного мастера, разбиваются на несколько, согласно количеству мастеров.`,
            },
            {
                text: 'Клиентов',
                value: 'clients',
                sort: numSort,
                class: 'h-clients',
                desc: `Количество уникальных клиентов за период.`,
            },
            {
                text: 'Новых',
                value: 'newClients',
                sort: numSort,
                class: 'h-newClients',
                desc: `Количество тех, кто не был у этого мастера в последние 2.5 года перед промежутком, и пришел в промежутке.`,
            },
            {
                text: 'Постоянных ',
                value: 'regularClients',
                sort: numSort,
                class: 'h-regularClients',
                desc: `Количество тех, кто уже был у этого мастера в последние 2.5 года перед промежутком, и пришел в промежутке.`,
            },
            {
                text: 'ОПЗ',
                value: 'recordOtherDay',
                sort: floatNumSort,
                class: 'h-recordOtherDay',
                desc: `Количество клиентов, у которых в день визита в указанном промежутке появилась запись на другой день, делим на количество клиентов, которые были у этого мастера в промежутке. Сами записи могут быть на дату за пределами промежутка. Если клиент пришел к мастеру Х, а записался в тот же день на будущее к мастеру Y, это учитывается, как ОПЗ мастера Y.`,
            },
            {
                text: 'Возвратность',
                value: 'returnability',
                sort: floatNumSort,
                class: 'h-returnability',
                desc: `Считаем, какой процент клиентов из тех, кто приходил к мастеру в последние 240 дней перед промежутком, вернулся в промежутке.`,
            },
            {
                text: 'Средний чек',
                value: 'averageСheck',
                sort: numSort,
                class: 'h-averageСheck',
                desc: `Выручку по товарам и услугам делим на количество визитов.`,
            },
            {
                text: 'Отработано смен ',
                value: 'shiftСount',
                sort: numSort,
                class: 'h-shiftСount',
                desc: `Количество уникальных дат, в которые у мастера был хотя бы один прием.`,
            },
            {
                text: 'Дневная выручка ',
                value: 'averageShift',
                sort: numSort,
                class: 'h-averageShift',
                desc: `Делим выручку по товарам и услугам на количество смен.`,
            },
        ],
        tableTooltip: {
            activator: undefined as string | undefined,
            text: '',
        },
        dashboardData: {
            statByStaffData: [],
        } as any,
    }),
    watch: {},
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        projectIsNetwork(): boolean {
            return this.projectType === 'network'
        },
        connector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        breadcrumbs(): Array<any> {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'Отчет по мастерам',
                    to: '',
                    disabled: true,
                },
            ]
        },
        dateRangeText(): string {
            const formatedDates = this.sortedDates.map(date =>
                moment(date, this.datePickerDateFormat).format(this.dateFormat)
            )
            return formatedDates.join(' - ')
        },
        sortedDates(): string[] {
            return [
                ...this.dates.sort(
                    (a, b) =>
                        moment(a, this.datePickerDateFormat).unix() -
                        moment(b, this.datePickerDateFormat).unix()
                ),
            ]
        },
        dateBorders(): { to: string; from: string } {
            return {
                from: this.sortedDates[0],
                to: this.sortedDates[1],
            }
        },
        displayFilters(): any {
            return {
                staffList: Object.values(this.filters.staffList ?? {})
                    .filter((el: any) => el.selected)
                    .map((el: any) => el.id),
                filialList: Object.values(this.filters.filialList ?? {})
                    .filter((el: any) => el.selected)
                    .map((el: any) => el.id),
            }
        },
        queryFilters(): any {
            return _.pickBy(
                {
                    dateFrom: this.sortedDates[0],
                    dateTo: this.sortedDates[1],
                    staffList: this.filters.staffList,
                    filialList: this.projectIsNetwork ? this.filters.filialList : undefined,
                },
                (v, k) => {
                    if (_.isObject(v) && _.isEmpty(v)) {
                        return false
                    }
                    if (
                        !_.isNil(v) &&
                        this.displayFilters[k] &&
                        (this.filtersLists[k].length === this.displayFilters[k].length ||
                            this.displayFilters[k].length === 0)
                    ) {
                        return false
                    }
                    return true
                }
            )
        },
        statByStaffData(): any[] {
            const formatNumber = (num: number) => new Intl.NumberFormat('ru-RU').format(num)
            return this.dashboardData.statByStaffData.map((el: any) => ({
                staffName: el.staff_name,
                servRev: formatNumber(el.serv_rev ?? 0),
                prodRev: formatNumber(el.prod_rev ?? 0),
                visits: formatNumber(el.visits ?? 0),
                clients: formatNumber(el.clients ?? 0),
                newClients: formatNumber(el.new_clients ?? 0),
                regularClients: formatNumber(el.regular_clients ?? 0),
                recordOtherDay: `${formatNumber(el.record_other_day ?? 0)}%`,
                returnability: `${formatNumber(el.returnability ?? 0)}%`,
                averageСheck: formatNumber(el.average_check ?? 0),
                shiftСount: formatNumber(el.shift_count ?? 0),
                averageShift: formatNumber(el.average_shift ?? 0),
            }))
        },
    },
    methods: {
        setFiltersLists() {
            this.filtersLists = {
                staffList: Object.values(this.filters.staffList ?? {}).map((el: any) => ({
                    id: el.id,
                    name: el.option,
                })),
                filialList: Object.values(this.filters.filialList ?? {}).map((el: any) => ({
                    id: el.id,
                    name: el.option,
                })),
            }
        },
        setDefaultDates() {
            this.dates = [
                moment()
                    .subtract(60, 'days')
                    .format(this.datePickerDateFormat),
                moment()
                    .subtract(30, 'days')
                    .format(this.datePickerDateFormat),
            ]
        },

        dropFilters() {
            this.setDefaultDates()
            this.filters = Object.assign({}, this.inititalFilters)
            this.initData()
        },
        setFilterValue(filterId: string, val: any) {
            const updatedFilterVal = {}
            const selectedHmap = val.reduce((a: any, c: any) => Object.assign(a, { [c]: true }), {})
            this.filtersLists[filterId].forEach((el: any) => {
                _.setWith(
                    updatedFilterVal,
                    [el.id],
                    {
                        id: el.id,
                        option: el.name,
                        selected: Boolean(selectedHmap[el.id]),
                        service_group: el.service_group,
                    },
                    Object
                )
            })
            this.filters = Object.assign({}, this.filters, { [filterId]: updatedFilterVal })
        },
        async initData() {
            this.dashboardDataLoading = true
            const { data, error } = await api.gcb2.getNewStaffReportData({
                projectId: this.project.id,
                filters: this.queryFilters,
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Ошибка')
            }
            this.dashboardData = data.reportData
            this.filters = Object.assign({}, this.filters, data.filters)
            this.setFiltersLists()
            this.dashboardDataLoading = false
        },
        async handleTableMouseOver(e: MouseEvent) {
            const target = e.target as HTMLElement
            const header = [target, target?.parentElement].find(el => el?.tagName === 'TH')
            const headerId = [...((header?.classList as any) || [])].find(cl => cl.includes('h-'))
            if (headerId) {
                if (this.tableTooltip.activator !== `.${headerId}`) {
                    this.tableTooltip.activator = undefined
                    const headerInfo = this.statByStaffDataHeaders.find(h => h.class === headerId)
                    this.tableTooltip.text = headerInfo?.desc || ''
                    this.$nextTick(() => {
                        this.tableTooltip.activator = `.${headerId}`
                    })
                }
            }
        },
    },
    async mounted() {
        this.inititalFilters = Object.assign({}, this.filters)
        this.pageLoading = true
        this.setDefaultDates()
        this.projectType = await api.project
            .getType({ projectId: this.project.id })
            .then(res => res.data?.type)
        await this.initData()
        this.pageLoading = false
    },
})
